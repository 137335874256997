<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="24"
    viewBox="0 0 20 24"
    :class="color"
  >
    <path
      class="a"
      d="M523,2106s-10-4.285-10-13.714v-6.858A30.754,30.754,0,0,0,523,2082a30.754,30.754,0,0,0,10,3.428v6.858C533,2101.715,523,2106,523,2106Z"
      transform="translate(-513 -2082)"
    />
    <path
      class="b"
      d="M524.165,2088l1.417,4.2h4.583l-3.708,2.6,1.417,4.2-3.708-2.6-3.708,2.6,1.416-4.2-3.708-2.6h4.584Z"
      transform="translate(-514.166 -2083)"
    />
  </svg>
</template>

<script>
export default {
  name: "Marshall",
  props: ["color"],
};
</script>

<style lang="scss" scoped>
.white {
  .b {
    fill: #e0bd78;
  }
  .a {
    fill: #fff;
  }
}
.a {
  fill: #e0bd78;
}
.b {
  fill: #fff;
}
</style>