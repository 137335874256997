<template>
  <div class="group-container">
    <div class="header">
      <p>Hole {{ holeDisplayName(group.startHole) }}</p>
      <p class="time" v-if="group.startsAt != null">{{ startTime }}</p>
    </div>

    <div
      class="current-marshall"
      v-if="group.marshall != null"
      title="Scoring Marshall"
    >
      <Marshall class="icon" />
      <div class="name">
        <p>{{ group.marshall.firstName }}</p>
        <span>{{ group.marshall.lastName }}</span>
      </div>
    </div>

    <div
      class="player"
      v-for="player in group.playerConnections"
      :key="player.id"
      :class="{
        selected: selectedPlayer && selectedPlayer.id == player.id,
        tapped: tapNotification == player.id && selectedPlayer.id != player.id,
      }"
    >
      <div
        class="division dnf-dns"
        :title="player.player.division.type"
        v-if="player.player.dns.isDns || player.player.dnf.isDnf"
      >
        <span v-if="player.player.dnf.isDnf">DNF</span>
        <span v-else-if="player.player.dns.isDns">DNS</span>
      </div>
      <div class="division" v-else>
        <span>{{ player.player.division.type }}</span>
      </div>

      <div class="name">
        {{ player.player.user.firstName }}
        <span>{{ player.player.user.lastName }}</span>
      </div>
    </div>
    <div
      class="player"
      v-for="emptyPlayers in 5 - group.playerConnections.length"
      :key="emptyPlayers"
    >
      <div class="division none">
        <span></span>
      </div>
      <div></div>
      <span></span>
    </div>
    <div class="footer prepare" v-if="group.status == 'CLOSED'">
      <p class="title">Upcoming</p>
      <span></span>
    </div>
    <div class="footer ongoing" v-else-if="group.status == 'OPEN'">
      <p class="title">Live</p>
      <span></span>
    </div>
    <div class="footer done" v-else-if="group.status == 'DONE'">
      <p class="title">Finished</p>
      <MidnightCheckmark />
    </div>
    <div class="footer" v-else>
      <p class="title"></p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ClickOutside from "vue-click-outside";
import MidnightCheckmark from "@/assets/icons/MidnightCheckmark";
import Marshall from "@/assets/icons/Marshall";

export default {
  name: "StandardPublicPreliminaryGroup",
  props: [
    "roundId",
    "group",
    "isResultSigningEnabled",
    "selectedPlayer",
    "selectedGroup",
    "roundNumber",
    "startMethod",
    "allowStartTime",
  ],
  components: {
    MidnightCheckmark,
    Marshall,
  },
  directives: {
    ClickOutside,
  },
  watch: {
    group: {
      deep: true,
      handler() {
        if (this.group.marshall == null) {
          this.showMarshallField = false;
          this.marshallEmail = "";
        }
      },
    },
  },
  data() {
    return {
      expandedPlayerMenu: "",
      marshallEmail: "",
      showMarshallField: false,
      tapNotification: null,
      startHour: 0,
      startMinute: 0,
    };
  },
  computed: {
    showUnsignedBadge() {
      let badgesToShow = [];
      let atleastOneSigned = false;

      if (this.group.status == "OPEN") {
        this.group.playerConnections.forEach((player) => {
          if (player.hasSignedResult == false) {
            badgesToShow.push(player.player.id);
          } else {
            atleastOneSigned = true;
          }
        });
      }

      return atleastOneSigned ? badgesToShow : [];
    },
    startTime() {
      return moment(this.group.startsAt).format("HH:mm");
    },
    showEditTimeOption() {
      if (this.group.startsAt == null || this.showMarshallField) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    holeDisplayName(hole) {
      if (hole.name != null) {
        return hole.name;
      } else {
        return hole.number;
      }
    },
  },
  mounted() {
    if (this.group.marshall != null) {
      this.showMarshallField = true;
    }
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.group-container {
  width: calc(50% - 5px);
  margin-bottom: 44px;
  position: relative;

  &.ongoing {
    .player {
      cursor: default;
    }
    .header {
      cursor: default;
    }
  }
}

.edit-bullets {
  display: flex;
  width: 26px;
  height: 26px;
  justify-content: space-around;
  padding: 1px;
  align-items: center;
  transition: 0.3s;
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: $fog;
    transition: 0.3s;
  }

  span {
    width: 4px;
    height: 4px;
    background-color: $midnight;
    display: flex;
    border-radius: 50%;
  }
}

.tee-time {
  height: 40px;
  border-left: 1px solid $fog;
  border-right: 1px solid $fog;
  background-color: white;
  border-top: 1px solid $fog;
  font-size: 16px;
  @include Gilroy-Bold;
  line-height: 16px;

  .timePlaceholder {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: $teal;
    @include Gilroy-Regular;
    cursor: pointer;
  }

  .edit-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 10px;
    p {
      width: 15%;
      margin: 0;
      font-size: 12px;
      color: $teal;
      cursor: pointer;
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70%;
    }
    .check {
      height: 24px;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input {
      width: 30%;
      text-align: center;
      border-radius: 4px;
      border: 1px solid $fog;
      font-size: 14px;
      @include Gilroy-Regular;
      height: 30px;
      outline: none;

      &.left {
        margin-right: 5px;
      }
      &.right {
        margin-left: 5px;
        margin-right: 10px;
      }
    }
  }

  .displayed-time {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    p {
      margin: 0;
      font-size: 14px;

      @include Gilroy-Bold;
    }
    svg {
      height: 14px;
      cursor: pointer;
    }
  }
}

.header {
  background-color: $fog;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  height: 32px;
  color: $midnight;
  display: flex;
  justify-content: space-between;
  transition: all ease 0.3s;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;
  p {
    @include Gilroy-Bold;
    font-size: 14px;
    margin: 0;
    color: $midnight;
  }
}
.current-marshall {
  background-color: #f9f2e3;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  width: 100%;
  padding-left: 10px;
  font-size: 12px;

  .icon {
    width: 16px;
    margin-right: 10px;
  }

  .name {
    display: flex;
    flex-direction: column;
    p {
      text-transform: uppercase;
      margin: 0;
      color: $midnight;
      font-size: 12px;
      @include Gilroy-Bold;
    }
    span {
      font-size: 11px;
      color: $midnight;
      @include Gilroy-Regular;
    }
  }
}
.player {
  border: 1px solid $fog;
  border-bottom: 0px;
  min-height: 48px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  cursor: pointer;
  transition: all easeOutExpo 0.25s;
  box-sizing: border-box;
  position: relative;

  &.empty-spot-disabled {
    cursor: default;
  }

  .division {
    width: 16px;
    min-width: 16px;
    background-color: $mist;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    cursor: default;

    &.none {
      background-color: transparent;
    }

    &.signed {
      span {
        letter-spacing: 0.45;
        color: white;
      }

      background-color: $sky;
    }
    &.dnf-dns {
      span {
        color: white;
      }

      background-color: $dusk;
    }

    span {
      display: flex;
      transform: rotate(-90deg);
      font-size: 8px;
      justify-content: center;
      align-items: center;
      @include Gilroy-Bold;
      letter-spacing: 1.2px;
      text-transform: uppercase;
      color: $strom;
    }
  }

  .name {
    font-size: 12px;
    text-transform: uppercase;
    @include Gilroy-Bold;
    padding-left: 10px;
    display: flex;
    flex-wrap: wrap;
    max-width: calc(100% - 60px);
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    line-height: 16px;

    span {
      text-transform: none;
      display: flex;
      align-items: center;
      width: 100%;
      @include Gilroy-Regular;
      font-size: 11px;
      line-height: 16px;
    }
  }
}

.footer {
  background-color: $fog;
  letter-spacing: 0.3px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 28px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-right: 10px;
  p {
    margin: 0;
    font-size: 10px;
    letter-spacing: 0.5px;
    color: $strom;
    @include Gilroy-Bold;
    text-transform: uppercase;
  }
  span {
    display: flex;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-left: 6px;
  }
  &.prepare {
    span {
      background-color: $sunrise;
    }
  }

  &.ongoing {
    span {
      background-color: $grass;
    }
  }

  &.done {
    svg {
      width: 12px;
      height: 12px;
      margin-left: 6px;
    }
  }
  span {
    display: flex;
    align-items: center;
    font-size: 10px;
    justify-content: flex-end;
    padding-right: 10px;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .group-container {
    width: 30%;
    max-width: 225px;
    margin-bottom: 44px;

    .header {
      height: 40px;

      p {
        font-size: 16px;
      }
    }
    .tee-time {
      .displayed-time {
        p {
          font-size: 16px;
        }
      }
    }

    .player {
      .division {
        height: 58px;
        width: 23px;
        span {
          font-size: 11px;
        }
      }
      .name {
        width: calc(100% - 75px);
        font-size: 16px;
        line-height: 1.4em;
        span {
          font-size: 14px;
        }
      }
    }
    .footer {
      height: 32px;
      line-height: unset;
      p {
        font-size: 12px;
        @include Gilroy-Bold;
      }
    }
  }
  .current-marshall {
    font-size: 16px;
    height: 58px;
    .name {
      p {
        font-size: 16px;
        line-height: 1.4em;
      }
      span {
        font-size: 14px;
        line-height: 16px;
      }
    }
    span {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>