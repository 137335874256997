<template>
  <div class="pool-groups" v-if="loaded">
    <div class="placeholder-message" v-if="poolData.areGroupsPublic == false">
      <h4>No groups created yet</h4>
      <p>
        When the event organiser publishes the groups they will be displayed
        here.
      </p>
    </div>
    <div
      class="no-groups"
      v-if="poolData.status == 'PREPARE' && poolData.areGroupsPublic == true"
    >
      <h3>Preliminary groups</h3>
      <p>
        Groups may be changed by the organiser before the
        {{ poolData.round.pools.length > 1 ? "pool" : "round" }}
        starts.
      </p>
    </div>
    <div class="groups-list" v-if="poolData.groups.length > 0">
      <StandardPublicPreliminaryGroup
        v-for="group in poolData.groups"
        :key="group.id"
        :group="group"
      />
    </div>
  </div>
</template>

<script>
import StandardPublicPreliminaryGroup from "@/components/StandardPublicPreliminaryGroup";

export default {
  name: "PublicEventPoolGroups",
  components: { StandardPublicPreliminaryGroup },
  data() {
    return { poolData: null, loaded: false };
  },
  watch: {
    $route: function () {
      this.loadData();
    },
  },
  computed: {},
  methods: {
    async loadData() {
      let poolData = await this.$axios({
        data: {
          query: `{
            pool(poolId:"${this.$route.params.poolId}"){
              id
              status
              startMethod
              areGroupsPublic
              groups{
                id
                status
                position
                startsAt
                marshall{
                  id
                  firstName
                  lastName
                }
                startHole{
                  id
                  number
                  par
                  name
                }
                playerConnections{
                  id
                  position
                  playerId
                  groupId
                  player{
                    id
                    user{
                      firstName
                      lastName
                    }
                    division{
                      id
                      type
                      name
                    }
                    dnf{
                      isDnf
                    }
                    dns{
                      isDns
                    }
                  }
                  hasSignedResult

                }
              }
              round{
                id
                status
                pools{
                  id
                }
                event{
                  id
                  isResultSigningEnabled
                }
              }
            }
          }
          `,
        },
      });

      this.poolData = poolData.data.data.pool;
      this.loaded = true;
    },
  },
  beforeMount() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.no-groups {
  padding: 0 12px;
  margin-bottom: 32px;
  h3 {
    margin-bottom: 16px;
    font-size: 24px;
    @include Gilroy-Bold;
  }
  p {
    font-size: 14px;
  }
}
.groups-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 12px;
  .group-container {
    width: 48%;
  }
}
.placeholder-message {
  margin-top: 50px;
  width: 100%;
  text-align: center;
  h4 {
    font-size: 20px;
    margin-bottom: 16px;
  }
  p {
    font-size: 16px;
    color: $strom;
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .pool-groups {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .group-container {
      width: 30.3%;
      max-width: unset;
      margin-right: 3%;

      @media (max-width: 1200px) {
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .no-groups {
    padding: 0;
    margin-bottom: 32px;
    h3 {
      margin-bottom: 12px;
      font-size: 32px;
      @include Gilroy-Bold;
    }
    p {
      font-size: 16px;
    }
  }
  .groups-list {
    padding: 0;
    justify-content: flex-start;
  }
  .pool-groups {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0px;

    .group-container {
      width: 18%;
      max-width: 18%;
      margin-right: 2.5%;
      &:nth-child(5n) {
        margin-right: 0;
      }
    }
  }
  .placeholder-message {
    text-align: center;
    h4 {
      font-size: 24px;
      margin-bottom: 24px;
    }
    p {
      font-size: 18px;
      color: $strom;
    }
  }
}
</style>
