<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g transform="translate(4243 16385)">
      <g transform="translate(-5033 -16843)">
        <circle class="a" cx="8" cy="8" r="8" transform="translate(790 458)" />
        <path class="b" d="M3.5,0V6H0" transform="translate(798.743 461) rotate(45)" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "MidnightCheckmark"
};
</script>

<style lang="scss" scoped>
.a {
  fill: #2d323c;
}
.b {
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
}
</style>